import React, { useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Contact from "./Contact.js";
import Service from "./Service.js";
import Slider from "./Slider.js";

function Content() {
    const aboutRef = useRef(null);
    const contactRef = useRef(null);
    const serviceRef = useRef(null);

    const scrollToAbout = () => {
        aboutRef.current.scrollIntoView({ behavior: "smooth" });
    };

    const scrollToService = () => {
        serviceRef.current.scrollIntoView({ behavior: "smooth" });
    };

    const scrollToContact = () => {
        contactRef.current.scrollIntoView({ behavior: "smooth" });
    };
    return (
        <div>
            <Header scrollToAbout={scrollToAbout} scrollToContact={scrollToContact} scrollToService={scrollToService} />
            <Slider />
            <div ref={serviceRef}>
                <Service />
            </div>
            <div ref={contactRef}>
                <Contact />
            </div>
            <div ref={aboutRef}>
                <div></div>
            </div>
            <Footer />
        </div>
    );
}

export default Content;